<template>
  <b-card no-body class="dashboard-card">
    <b-overlay :show="loading" rounded="sm" variant="light">
      <b-card-body class="d-flex justify-content-between align-items-center">
        <div class="truncate">
          <h6 class="mb-25 font-weight-bolder">
            {{ title }}
          </h6>
          <h1 class="font-weight-bolder" :class="`text-${color}`">
            {{ statistic }}
          </h1>
        </div>
        <b-avatar :variant="`light-${color}`" size="45">
          <feather-icon size="21" icon="BoxIcon" />
        </b-avatar>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BLink, BOverlay, BAvatar } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardBody,
    BLink,
    BOverlay,
    BAvatar,
  },
  props: {
    statistic: {
      type: [Number, String],
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
