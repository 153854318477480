<template>
  <b-card no-body v-if="chartData.labels">
    <b-overlay :show="loading" rounded="sm" variant="light">
      <b-card-header>
        <div>
          <b-card-title class="mb-1">
            {{ $t("field.orderVsCancelOrderVolume") }}
            <span v-if="dateFrom && dateTo">
              <span v-if="dateFrom != dateTo">
                {{
                  $t("general.datePeriod", {
                    dateFrom: moment(dateFrom)
                      .locale($i18n.locale)
                      .format("LL"),
                    dateTo: moment(dateTo).locale($i18n.locale).format("LL"),
                  })
                }}
              </span>
              <span v-else>
                {{ moment(dateFrom).locale($i18n.locale).format("LL") }}
              </span>
            </span>
          </b-card-title>
        </div>
      </b-card-header>

      <b-card-body>
        <chartjs-component-bar-chart
          :key="`order-chart${key}`"
          :height="300"
          :data="chartjsData.barChart.data"
          :options="chartjsData.barChart.options"
          :plugins="plugins"
        />
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
  BButtonGroup,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import ChartjsComponentBarChart from "@/components/charts-components/ChartjsComponentBarChart.vue";
import chartjsData from "./chartjsData";
import Ripple from "vue-ripple-directive";
import moment from "moment";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    BButtonGroup,
    BButton,
    BOverlay,
    ChartjsComponentBarChart,
  },
  directives: {
    Ripple,
  },
  props: {
    chartData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    dateFrom: {
      type: String,
      default: "",
    },
    dateTo: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentLocale() {
      return this.$i18n.locale;
    },
  },
  watch: {
    currentLocale(value, oldValue) {
      if (oldValue && value && this.chartData) {
        this.mapChartData(this.chartData);
      }
    },
  },
  data() {
    return {
      chartjsData,
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 20;
            };
            /* eslint-enable */
          },
        },
      ],
      key: 1,
      moment,
    };
  },
  methods: {
    mapChartData(data) {
      let labels = [];
      switch (data.period) {
        case "hour":
          for (let label of data.labels) {
            labels.push(
              moment(`${label}`).locale(this.currentLocale).format("hh:mm a")
            );
          }
          break;
        case "day":
          for (let label of data.labels) {
            labels.push(
              moment(`${label}`).locale(this.currentLocale).format("Do MMM")
            );
          }
          break;
        case "month":
          for (let label of data.labels) {
            labels.push(
              moment(`${label}`).locale(this.currentLocale).format("MMM YYYY")
            );
          }
          break;
        case "year":
          for (let label of data.labels) {
            labels.push(
              moment(`${label}`).locale(this.currentLocale).format("YYYY")
            );
          }
          break;
      }

      this.chartjsData.barChart.data.labels = [...labels];
      this.chartjsData.barChart.data.datasets[0].data = [...data.orders];
      this.chartjsData.barChart.data.datasets[0].label = this.$t("field.orderVolume");
      this.chartjsData.barChart.data.datasets[1].data = [...data.cancelOrders];
      this.chartjsData.barChart.data.datasets[1].label =
        this.$t("field.cancelOrderVolume");
      this.key++;
    },
  },
};
</script>
