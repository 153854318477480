<template>
  <b-card no-body class="dashboard-card">
    <b-overlay :show="loading" rounded="sm" variant="light">
      <b-card-body class="d-flex justify-content-between align-items-center">
        <div class="truncate">
          <h6 class="mb-25 font-weight-bolder">
            {{ title }}
          </h6>
          <h1 class="font-weight-bolder" :class="`text-${color}`">
            {{ statistic }}
          </h1>
          <b-link
            class="link-underline"
            :class="`text-${color}`"
            v-if="$can('read', 'order')"
            :to="{
              name: 'list-order',
              query: {
                state: state,
                agentId: agentId,
                orderedDate: period,
                orderedDateFrom: dateFrom,
                orderedDateTo: dateTo,
              },
            }"
          >
            {{ $t("button.viewOrder") }}
          </b-link>
        </div>
        <b-avatar :variant="`light-${color}`" size="45">
          <feather-icon size="21" icon="ShoppingCartIcon" />
        </b-avatar>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BLink, BOverlay, BAvatar } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardBody,
    BLink,
    BOverlay,
    BAvatar,
  },
  props: {
    statistic: {
      type: [Number, String],
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    state: {
      type: String,
      default: null,
    },
    agentId: {
      type: Number,
      default: null,
    },
    period: {
      type: String,
      default: null,
    },
    dateFrom: {
      type: String,
      default: null,
    },
    dateTo: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: "primary",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
