<template>
  <div>
    <load-profile></load-profile>
    <n-search-container
      @search="search"
      @reset="reset"
      :fields="searchFields"
      :loading="loading"
      :default-visible="true"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
        <template #dateFrom>
          <div v-if="params.period != 'other'"></div>
        </template>
        <template #dateTo>
          <div v-if="params.period != 'other'"></div>
        </template>
      </n-search-input>
    </n-search-container>

    <b-row class="match-height">
      <b-col md="12" lg="6">
        <StatisticCardHorizontal
          :loading="loading"
          :statistic="data.totalNewOrder"
          :title="$t('field.newOrder')"
          state="pending"
          :agent-id="query.agentId"
          :period="query.period"
          :date-from="query.dateFrom"
          :date-to="query.dateTo"
          color="warning"
        />
      </b-col>
      <b-col md="12" lg="6">
        <StatisticCardHorizontal
          :loading="loading"
          :statistic="data.totalInProgressOrder"
          :title="$t('field.inProgressOrder')"
          state="preparing,shipping"
          :agent-id="query.agentId"
          :period="query.period"
          :date-from="query.dateFrom"
          :date-to="query.dateTo"
          color="primary"
        />
      </b-col>
      <b-col md="12" lg="6">
        <StatisticCardHorizontal
          :loading="loading"
          :statistic="data.totalCompleteOrder"
          :title="$t('field.completeOrder')"
          state="complete"
          :agent-id="query.agentId"
          :period="query.period"
          :date-from="query.dateFrom"
          :date-to="query.dateTo"
          color="success"
        />
      </b-col>
      <b-col md="12" lg="6">
        <StatisticCardHorizontal
          :loading="loading"
          :statistic="data.totalCancelOrder"
          :title="$t('field.cancelOrder')"
          state="reject,cancel"
          :agent-id="query.agentId"
          :period="query.period"
          :date-from="query.dateFrom"
          :date-to="query.dateTo"
          color="danger"
        />
      </b-col>
      <b-col md="12" >
        <order-chart
          ref="orderChart"
          :loading="loading"
          :chart-data="data.orderChart"
          :date-from="query.dateFrom"
          :date-to="query.dateTo"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import SearchInputs from "./searchInput";
import { calculateDatesByPeriod } from "@/libs/helper";
import StatisticCardHorizontal from "./StatisticCardHorizontal.vue";
import OrderChart from "./OrderChart";
import Repository from "@/repositories/RepositoryFactory";

const AgentRepository = Repository.get("agent");

export default {
  components: {
    BRow,
    BCol,

    NSearchContainer,
    NSearchInput,

    StatisticCardHorizontal,
    OrderChart,
  },
  watch: {
    perPage() {
      this.list();
    },
    "params.period": function (value) {
      if (value) {
        this.calculateDate();
      }
    },
  },
  data() {
    return {
      loading: false,
      params: {
        tabIndex: Number(this.$route.query.tabIndex) || 0,
        agentId: Number(this.$route.params.id) || null,
        period: this.$route.query.period || "thisMonth",
        dateFrom: this.$route.query.dateFrom || null,
        dateTo: this.$route.query.dateTo || null,
      },
      query: {
        tabIndex: Number(this.$route.query.tabIndex) || 0,
        agentId: Number(this.$route.params.id) || null,
        period: this.$route.query.period || "thisMonth",
        dateFrom: this.$route.query.dateFrom || null,
        dateTo: this.$route.query.dateTo || null,
      },
      data: {
        totalNewOrder: 0,
        totalInProgressOrder: 0,
        totalCompleteOrder: 0,
        totalCancelOrder: 0,
        orderChart: {},
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    calculateDate() {
      let dates;
      if (this.params.period == "other") {
        dates = calculateDatesByPeriod("thisMonth");
      } else {
        dates = calculateDatesByPeriod(this.params.period);
      }
      if (dates.length) {
        this.params.dateFrom = dates[0];
        this.params.dateTo = dates[1];
      }
    },
    updateQuerySting() {
      this.$router.replace({
        query: this.query,
      });
    },
    search() {
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.params.period = "thisMonth";
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.updateQuerySting();
      this.getData();
    },
    getData() {
      this.loading = true;
      if (!this.query.dateFrom || !this.query.dateFrom) {
        this.calculateDate();
        this.query = {
          ...this.query,
          ...this.params,
        }
      }
      AgentRepository.orderReport(this.query)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.data.totalNewOrder = data.totalNewOrder || 0;
            this.data.totalInProgressOrder = data.totalInProgressOrder || 0;
            this.data.totalCompleteOrder = data.totalCompleteOrder || 0;
            this.data.totalCancelOrder = data.totalCancelOrder || 0;
            
            this.data.orderChart = data.orderChart;
            this.$refs.orderChart.mapChartData(data.orderChart);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const searchFields = SearchInputs;

    return {
      searchFields,
    };
  },
};
</script>