var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"dashboard-card",attrs:{"no-body":""}},[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm","variant":"light"}},[_c('b-card-body',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"truncate"},[_c('h6',{staticClass:"mb-25 font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('h1',{staticClass:"font-weight-bolder",class:("text-" + _vm.color)},[_vm._v(" "+_vm._s(_vm.statistic)+" ")]),(_vm.$can('read', 'order'))?_c('b-link',{staticClass:"link-underline",class:("text-" + _vm.color),attrs:{"to":{
            name: 'list-order',
            query: {
              state: _vm.state,
              agentId: _vm.agentId,
              orderedDate: _vm.period,
              orderedDateFrom: _vm.dateFrom,
              orderedDateTo: _vm.dateTo,
            },
          }}},[_vm._v(" "+_vm._s(_vm.$t("button.viewOrder"))+" ")]):_vm._e()],1),_c('b-avatar',{attrs:{"variant":("light-" + _vm.color),"size":"45"}},[_c('feather-icon',{attrs:{"size":"21","icon":"ShoppingCartIcon"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }