<template>
  <b-card :title="$t('field.orderVolumeByCategory')">
    <b-overlay :show="loading" rounded="sm" variant="light">
      <b-row>
        <b-col md="12" xl="6">
          <chartjs-component-pie-chart
            ref="orderVolumeByCategoryChart"
            :data="chartjsData.orderVolumeByCategoryChart.data"
            :options="chartjsData.orderVolumeByCategoryChart.options"
            :key="`order-volume-by-category-${key}`"
          />
        </b-col>
        <b-col md="12" xl="6">
          <div
            v-for="(brand, index) in brandData"
            :key="`detail-${index}`"
            :class="index < brandData.length - 1 ? 'mb-1' : ''"
            class="d-flex justify-content-between"
          >
            <div class="d-flex align-items-center">
              <feather-icon
                icon="CircleIcon"
                size="16"
                :style="{
                  color: brand.brandColor,
                  fill: brand.brandColor,
                }"
              />
              <span class="font-weight-bold ml-75">
                {{ trans(brand, "name", $i18n.locale) }}
              </span>
            </div>
            <div>
              <span
                :style="{
                  color: brand.brandColor,
                }"
              >
                {{ brand.quantity ? brand.quantity : 0 }} CTN
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BOverlay, BRow, BCol } from "bootstrap-vue";
import ChartjsComponentPieChart from "@/components/charts-components/ChartjsComponentPieChart.vue";
import chartjsData from "./chartjsData";

export default {
  components: {
    ChartjsComponentPieChart,
    BCard,
    BOverlay,
    BRow,
    BCol,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chartjsData,
      key: 1,
      brandData: [],
    };
  },
  methods: {
    mapData(brandData) {
      brandData.sort((a, b) => b.quantity - a.quantity);

      this.brandData = [];
      let labels = [];
      let data = [];
      let backgroundColor = [];
      const total = brandData.reduce((a, b) => a + (b.quantity || 0), 0);
      brandData.forEach((element, index) => {
        this.brandData.push(element);
        labels.push(
          this.$i18n.locale == "en" ? element.nameEn : element.nameKm
        );

        const percentage = Math.round((element.quantity * 100) / total);
        data.push(percentage);
        backgroundColor.push(element.brandColor);
      });
      this.chartjsData.orderVolumeByCategoryChart.data.datasets[0].labels = [
        ...labels,
      ];
      this.chartjsData.orderVolumeByCategoryChart.data.datasets[0].data = [
        ...data,
      ];
      this.chartjsData.orderVolumeByCategoryChart.data.datasets[0].backgroundColor =
        [...backgroundColor];
      this.key++;
    },
  },
};
</script>
