import { $themeColors } from '@themeConfig'
// colors
const chartColors = {
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  chartPrimary: '#003594',
  chartDanger: '#c0392b',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

export default {
  barChart: {
    data: {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: chartColors.chartPrimary,
          borderColor: 'transparent',
        },
        {
          data: [],
          backgroundColor: chartColors.chartDanger,
          borderColor: 'transparent',
        },
      ],
    },
    options: {
      elements: {
        rectangle: {
          borderWidth: 2,
          borderSkipped: 'bottom',
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      responsiveAnimationDuration: 500,
      legend: {
        display: true,
      },
      tooltips: {
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: chartColors.tooltipShadow,
        backgroundColor: $themeColors.light,
        titleFontColor: $themeColors.dark,
        bodyFontColor: $themeColors.dark,
      },
      scales: {
        xAxes: [
          {
            display: true,
            gridLines: {
              display: true,
              color: chartColors.grid_line_color,
              zeroLineColor: chartColors.grid_line_color,
            },
            scaleLabel: {
              display: false,
            },
            ticks: {
              fontColor: chartColors.labelColor,
            },
          },
        ],
        yAxes: [
          {
            display: true,
            gridLines: {
              color: chartColors.grid_line_color,
              zeroLineColor: chartColors.grid_line_color,
            },
            ticks: {
              // stepSize: 100,
              min: 0,
              // max: 400,
              fontColor: chartColors.labelColor,
            },
          },
        ],
      },
    },
  },
  orderVolumeByBrandChart: {
    options: {
      responsive: true,
      maintainAspectRatio: true,
      responsiveAnimationDuration: 500,
      legend: { display: true },
      pieceLabel: {
        mode: 'percentage',
        precision: 1
      },
      tooltips: {
        callbacks: {
          label(tooltipItem, data) {
            const label = data.datasets[0].labels[tooltipItem.index] || ''
            const value = data.datasets[0].data[tooltipItem.index]
            const output = ` ${label} : ${value} %`
            return output
          },
        },
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: chartColors.tooltipShadow,
        backgroundColor: $themeColors.light,
        titleFontColor: $themeColors.dark,
        bodyFontColor: $themeColors.dark,
      },
    },
    data: {
      datasets: [
        {
          labels: [],
          data: [],
          backgroundColor: [],
          borderWidth: 0,
          pointStyle: 'rectRounded',
        },
      ],
    },
  },
  orderVolumeByCategoryChart: {
    options: {
      responsive: true,
      maintainAspectRatio: true,
      responsiveAnimationDuration: 500,
      legend: { display: true },
      pieceLabel: {
        mode: 'percentage',
        precision: 1
      },
      tooltips: {
        callbacks: {
          label(tooltipItem, data) {
            const label = data.datasets[0].labels[tooltipItem.index] || ''
            const value = data.datasets[0].data[tooltipItem.index]
            const output = ` ${label} : ${value} %`
            return output
          },
        },
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: chartColors.tooltipShadow,
        backgroundColor: $themeColors.light,
        titleFontColor: $themeColors.dark,
        bodyFontColor: $themeColors.dark,
      },
    },
    data: {
      datasets: [
        {
          labels: [],
          data: [],
          backgroundColor: [],
          borderWidth: 0,
          pointStyle: 'rectRounded',
        },
      ],
    },
  },
}
