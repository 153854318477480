<template>
  <div>
    <load-profile></load-profile>
    <n-search-container
      @search="search"
      @reset="reset"
      :fields="searchFields"
      :loading="loading"
      :default-visible="true"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
        <template #dateFrom>
          <div v-if="params.period != 'other'"></div>
        </template>
        <template #dateTo>
          <div v-if="params.period != 'other'"></div>
        </template>
      </n-search-input>
    </n-search-container>

    <b-row class="match-height">
      <b-col md="12" lg="6">
        <StatisticCardHorizontal
          :loading="loading"
          :statistic="data.orderVolume"
          :title="$t('field.orderVolume')"
          color="primary"
        />
      </b-col>
      <b-col md="12" lg="6">
        <StatisticCardHorizontal
          :loading="loading"
          :statistic="data.cancelOrderVolume"
          :title="$t('field.cancelOrderVolume')"
          color="danger"
        />
      </b-col>
      <b-col md="12">
        <order-volume-chart
          ref="orderVolumeChart"
          :loading="loading"
          :chart-data="data.orderVolumeChart"
          :date-from="query.dateFrom"
          :date-to="query.dateTo"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col md="12" lg="6">
          <order-volume-by-brand-pie-chart
            ref="orderVolumeByBrandPieChart"
            :loading="loading"
          ></order-volume-by-brand-pie-chart>
      </b-col>
      <b-col md="12" lg="6">
          <order-volume-by-category-pie-chart
            ref="orderVolumeByCategoryPieChart"
            :loading="loading"
          ></order-volume-by-category-pie-chart>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import SearchInputs from "./searchInput";
import { calculateDatesByPeriod } from "@/libs/helper";
import OrderVolumeChart from "./OrderVolumeChart";
import StatisticCardHorizontal from "./StatisticCardHorizontal.vue";
import OrderVolumeByBrandPieChart from "./OrderVolumeByBrandPieChart.vue";
import OrderVolumeByCategoryPieChart from "./OrderVolumeByCategoryPieChart.vue";
import Repository from "@/repositories/RepositoryFactory";

const AgentRepository = Repository.get("agent");

export default {
  components: {
    BRow,
    BCol,

    NSearchContainer,
    NSearchInput,

    StatisticCardHorizontal,
    OrderVolumeChart,
    OrderVolumeByBrandPieChart,
    OrderVolumeByCategoryPieChart,
  },
  watch: {
    perPage() {
      this.list();
    },
    "params.period": function (value) {
      if (value) {
        this.calculateDate();
      }
    },
  },
  data() {
    return {
      loading: false,
      params: {
        tabIndex: Number(this.$route.query.tabIndex) || 0,
        agentId: Number(this.$route.params.id) || null,
        period: this.$route.query.period || "thisMonth",
        dateFrom: this.$route.query.dateFrom || null,
        dateTo: this.$route.query.dateTo || null,
      },
      query: {
        tabIndex: Number(this.$route.query.tabIndex) || 0,
        agentId: Number(this.$route.params.id) || null,
        period: this.$route.query.period || "thisMonth",
        dateFrom: this.$route.query.dateFrom || null,
        dateTo: this.$route.query.dateTo || null,
      },
      data: {
        orderVolume: 0,
        cancelOrderVolume: 0,
        orderVolumeChart: {},
        orderVolumeByBrand: {},
        orderVolumeByCategory: {},
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    calculateDate() {
      let dates;
      if (this.params.period == "other") {
        dates = calculateDatesByPeriod("thisMonth");
      } else {
        dates = calculateDatesByPeriod(this.params.period);
      }
      if (dates.length) {
        this.params.dateFrom = dates[0];
        this.params.dateTo = dates[1];
      }
    },
    updateQuerySting() {
      this.$router.replace({
        query: this.query,
      });
    },
    search() {
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.params.period = "thisMonth";
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.updateQuerySting();
      this.getData();
    },
    getData() {
      this.loading = true;
      if (!this.query.dateFrom || !this.query.dateFrom) {
        this.calculateDate();
        this.query = {
          ...this.query,
          ...this.params,
        };
      }
      AgentRepository.orderVolumeReport(this.query)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.data.orderVolume = data.orderVolume || 0;
            this.data.cancelOrderVolume = data.cancelOrderVolume || 0;

            this.data.orderVolumeChart = data.orderVolumeChart;
            this.$refs.orderVolumeChart.mapChartData(data.orderVolumeChart);

            this.data.orderVolumeByBrand = data.orderVolumeByBrand;
            this.$refs.orderVolumeByBrandPieChart.mapData(data.orderVolumeByBrand);

            this.data.orderVolumeByCategory = data.orderVolumeByCategory;
            this.$refs.orderVolumeByCategoryPieChart.mapData(data.orderVolumeByCategory);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const searchFields = SearchInputs;

    return {
      searchFields,
    };
  },
};
</script>