<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 col-xl-10 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-xl-2 col-md-3 col-12"
    nav-class="nav-left"
    v-model="tabIndex"
  >
    <b-tab v-if="$can('read', 'order')">
      <template #title>
        <feather-icon icon="ShoppingCartIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("field.order") }}</span>
      </template>

      <order-list v-if="tabIndex === 0" />
    </b-tab>
    <b-tab v-if="$can('read', 'order')">
      <template #title>
        <feather-icon icon="BoxIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("field.orderVolume") }}</span>
      </template>

      <order-volume-list v-if="tabIndex === 1" />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTab, BTabs } from "bootstrap-vue";
import OrderList from "./order/List.vue";
import OrderVolumeList from "./orderVolume/List.vue";

export default {
  components: {
    BTabs,
    BTab,

    OrderList,
    OrderVolumeList,
  },
  watch: {
    tabIndex(value) {
      this.$router.replace({
        query: {
          tabIndex: value,
        },
      });
    },
  },
  data() {
    return {
      tabIndex: this.$route.query.tabIndex
        ? Number(this.$route.query.tabIndex)
        : 0,
      options: {},
    };
  },
  created() {
    if (!this.$can("read", "order")) {
      this.$router.push({ name: "not-authorized" });
    }
  },
  mounted() {},
};
</script>
